import React, { useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import VideoScreen from 'common/components/VideoScreen';
import { ScalingPaddington } from 'common/components/Paddington';
import { ScrollWrapper, PageWrapper, ImageContainer, TextContainer, PageContainer } from './styles';
import { useBackendStore } from 'services/backendService';
import Loader from 'components/Loader';
import { useHistory } from 'react-router-dom';
import Seo from '../../common/components/Seo';

export default function Home() {
  const env = useBackendStore(state => state.env);
  const history = useHistory();
  const wrapperRef = useRef();
  const home = useBackendStore(state => state.home);
  if (!home) return <Loader />;
  const { headerVideo, pageLinks, seo } = home;
  const { video, thumbnail } = headerVideo;

  const clickHandler = path => {
    history.push(path);
  };

  return (
    <>
      <Seo seoData={seo} />
      <VideoScreen video={headerVideo && video.url} poster={thumbnail.url} />
      <ScalingPaddington>
        <ScrollWrapper>
          {pageLinks &&
            pageLinks.map((link, index) => (
              <PageWrapper ref={wrapperRef} key={index} isActive={true}>
                <PageContainer onClick={() => clickHandler(link.link)}>
                  <ImageContainer image={env + link.thumbnail.url}>
                    <img src={env + link.thumbnail.url} />
                  </ImageContainer>
                  <TextContainer>
                    <ReactMarkdown>{link.headline}</ReactMarkdown>
                  </TextContainer>
                </PageContainer>
              </PageWrapper>
            ))}
        </ScrollWrapper>
      </ScalingPaddington>
    </>
  );
}
